/* import __COLOCATED_TEMPLATE__ from './standalone-copilot-access-cell-wrapper.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import templateOnlyComponent from '@ember/component/template-only';
import type Admin from 'embercom/models/admin';

interface Signature {
  Args: {
    column: {
      widthStyle: number;
    };
    row: {
      teammate: Admin;
      isCurrentAdmin: boolean;
    };
    tableActions: {};
  };
}

const StandaloneCopilotAccessCellWrapper = templateOnlyComponent<Signature>();
export default StandaloneCopilotAccessCellWrapper;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TableCells::TeammateList::StandaloneCopilotAccessCellWrapper': typeof StandaloneCopilotAccessCellWrapper;
  }
}
