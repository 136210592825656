/* import __COLOCATED_TEMPLATE__ from './attribute-protections-cell.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Attribute from 'embercom/models/attribute';

interface Signature {
  Args: {
    row: {
      attribute: Attribute;
    };
    column: {
      widthStyle: string;
    };
  };
}

export default class AttributeProtectionsCell extends Component<Signature> {
  @service declare appService: any;

  get app() {
    return this.appService.app;
  }

  get attribute() {
    return this.args.row.attribute;
  }

  get protectionStatus() {
    return this.attribute.preventUpdateFromMessenger;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TableCells::Attributes::AttributeProtectionsCell': typeof AttributeProtectionsCell;
    'table-cells/attributes/attribute-protections-cell': typeof AttributeProtectionsCell;
  }
}
