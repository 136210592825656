/* import __COLOCATED_TEMPLATE__ from './standalone-copilot-access-cell.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import { type SeatType, COPILOT_SEAT_TYPE } from 'embercom/lib/settings/seats/constants';
import type Admin from 'embercom/models/admin';
import type CopilotUsageService from 'embercom/services/copilot-usage-service';
import mapSelectionToSeatAndPermission from 'embercom/lib/settings/copilot-access-editor/map-selection-to-seat-and-permission';
import { NO_ACCESS, UNLIMITED_USAGE } from 'embercom/lib/settings/copilot-access-editor/constants';
import type CopilotDataService from 'embercom/services/copilot-data-service';

interface Signature {
  Args: {
    teammate: Admin;
    isCurrentAdmin: boolean;
  };
  Element: HTMLDivElement;
}

export default class StandaloneCopilotAccessCell extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare copilotUsageService: CopilotUsageService;
  @service declare customerService: $TSFixMe;
  @service declare permissionsMutatorService: $TSFixMe;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare store: Store;
  @service declare copilotDataService: CopilotDataService;

  get teammate() {
    return this.args.teammate;
  }

  get copilotAccessType() {
    // we don't show limited copilot access for standalone apps
    return this.teammate.copilotAccessType === UNLIMITED_USAGE ? UNLIMITED_USAGE : NO_ACCESS;
  }

  get copilotAccessTypeText() {
    // we don't show limited copilot access for standalone apps
    return this.copilotAccessType === UNLIMITED_USAGE
      ? this.intl.t('table-cells.teammate-list.standalone-copilot-access-cell.yes')
      : this.intl.t('table-cells.teammate-list.standalone-copilot-access-cell.no');
  }

  get accessTypeItems() {
    return [
      {
        value: '',
        isDisabled: true,
        component: 'table-cells/teammate-list/standalone-copilot-access-dropdown-header',
      },
      {
        text: this.intl.t('table-cells.teammate-list.copilot-access-cell.unlimited'),
        value: UNLIMITED_USAGE,
        isDisabled: false,
        component: 'table-cells/teammate-list/standalone-copilot-access-dropdown-item',
        componentAttrs: {
          description: this.intl.t(
            'table-cells.teammate-list.standalone-copilot-access-cell.unlimited-description',
          ),
          copilotSeatPrice: this.copilotSeatPrice,
          icon: 'person',
        },
      },
      {
        text: this.intl.t('table-cells.teammate-list.copilot-access-cell.off'),
        value: NO_ACCESS,
        isDisabled: false,
        component: 'table-cells/teammate-list/standalone-copilot-access-dropdown-item',
        componentAttrs: {
          description: this.intl.t(
            'table-cells.teammate-list.standalone-copilot-access-cell.off-description',
          ),
          icon: 'remove-participant',
        },
      },
    ];
  }

  @action async onCopilotAccessChange(value: typeof NO_ACCESS | typeof UNLIMITED_USAGE) {
    if (value === this.teammate.copilotAccessType) {
      return;
    }

    if (value === UNLIMITED_USAGE && !this.teammate.hasUnlimitedCopilotAccess) {
      this.confirmAddCopilotSeat();
    } else {
      this.toggleCopilotAccess(value);
      await this.saveChanges();
    }
  }

  private toggleCopilotAccess(value: typeof NO_ACCESS | typeof UNLIMITED_USAGE) {
    mapSelectionToSeatAndPermission(
      value,
      this.toggleSeat,
      this.teammate.currentAppPermissions,
      this.teammate.copilotAccessType,
    );
  }

  @action
  toggleSeat(seat: SeatType) {
    let isCopilotSeat = seat === COPILOT_SEAT_TYPE;

    if (this.teammate.seats.includes(seat)) {
      this.teammate.seats.removeObject(seat);

      if (isCopilotSeat) {
        this.copilotDataService.updateSeatUsage('decrement');
      }
    } else {
      this.teammate.seats.pushObject(seat);

      if (isCopilotSeat) {
        this.copilotDataService.updateSeatUsage('increment');
      }
    }
  }

  private async saveChanges() {
    await this.permissionsMutatorService.savePermissionAndSeats(
      this.teammate,
      this.teammate.currentAppPermissions,
      this.teammate.seats,
    );

    this.teammate.currentAppPermissions.rollbackAttributes();
    this.store.findRecord('admin', this.teammate.id, {
      reload: true,
      backgroundReload: false,
    });
  }

  private async confirmAddCopilotSeat() {
    if (this.hasContractedSeatsLeft) {
      this.onConfirmAddCopilotSeat();
      return;
    }

    let confirmOptions = {
      title: this.intl.t('settings.copilot.standalone-modal-title'),
      primaryButtonType: 'primary',
      confirmButtonText: this.intl.t('settings.copilot.confirm'),
      cancelButtonText: this.intl.t('settings.copilot.cancel'),
      body: this.intl.t('settings.copilot.standalone-modal-body', { price: this.copilotSeatPrice }),
    };
    let confirmed = await this.intercomConfirmService.confirm(confirmOptions);

    if (confirmed) {
      this.onConfirmAddCopilotSeat();
    }
  }

  private get hasContractedSeatsLeft() {
    return (
      this.contractedSeatsRemaining > 0 &&
      (this.appService.app.isSalesforceContracted ||
        this.customerService.isP5SelfServeAnnualCustomer)
    );
  }

  private get contractedSeatsRemaining() {
    let { contractedLimit, used } = this.copilotDataService.copilotData.seatUsages;

    return contractedLimit - used;
  }

  private get copilotSeatPrice() {
    let priceInCents = this.copilotDataService.copilotData.price;

    return this.intl.formatNumber(priceInCents / 100, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    });
  }

  private onConfirmAddCopilotSeat() {
    this.toggleCopilotAccess(UNLIMITED_USAGE);

    this.saveChanges();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TableCells::TeammateList::StandaloneCopilotAccessCell': typeof StandaloneCopilotAccessCell;
    'table-cells/teammate-list/standalone-copilot-access-cell': typeof StandaloneCopilotAccessCell;
  }
}
