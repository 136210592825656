/* import __COLOCATED_TEMPLATE__ from './alias-cell.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import AdminSummary, { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';

interface Column {
  widthStyle: string;
}

interface Row {
  teammate: AdminSummaryWireFormat;
}

interface Args {
  column: Column;
  row: Row;
}

interface Signature {
  Args: Args;
  Element: HTMLTableCellElement;
}

export default class AliasCell extends Component<Signature> {
  teammate: AdminSummary | undefined;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    if (args.row.teammate != null) {
      this.teammate = this.convertToAdminSummary(args.row.teammate);
    }
  }

  private convertToAdminSummary(admin: AdminSummary | AdminSummaryWireFormat): AdminSummary {
    return admin instanceof AdminSummary ? admin : AdminSummary.deserialize(admin);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TableCells::TeammateList::AliasCell': typeof AliasCell;
    'table-cells/teammate-list/alias-cell': typeof AliasCell;
  }
}
