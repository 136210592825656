/* import __COLOCATED_TEMPLATE__ from './standalone-copilot-access-dropdown-header.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    componentAttrs: {
      description: string;
    };
    item: {
      text: string;
      isSelected: boolean;
    };
  };
}

const StandaloneCopilotAccessDropdownHeader = templateOnlyComponent<Signature>();
export default StandaloneCopilotAccessDropdownHeader;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TableCells::TeammateList::StandaloneCopilotAccessDropdownHeader': typeof StandaloneCopilotAccessDropdownHeader;
  }
}
