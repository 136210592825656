/* import __COLOCATED_TEMPLATE__ from './standalone-copilot-access-dropdown-item.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import templateOnlyComponent from '@ember/component/template-only';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
interface Signature {
  Args: {
    componentAttrs: {
      description: string;
      icon: InterfaceIconName;
      copilotSeatPrice: string;
    };
    item: {
      text: string;
      isSelected: boolean;
    };
  };
}

const StandaloneCopilotAccessDropdownItem = templateOnlyComponent<Signature>();
export default StandaloneCopilotAccessDropdownItem;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TableCells::TeammateList::StandaloneCopilotAccessDropdownItem': typeof StandaloneCopilotAccessDropdownItem;
  }
}
